import { SuperUserGuard } from './_guards/super-user.guard';
import { HasSelectedOrganizationGuard } from './_guards/has-selected-organization.guard';
import { AuthGuard } from './_guards/auth.guard';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {CanManageUsersGuard} from "./_guards/can-manage-users.guard";

const routes: Routes = [
    {
        path: '',
        component: HomeComponent
    },
    {
        path: 'manage/menu-items',
        loadChildren: () => import('./menu-items-manager/menu-items-manager.module').then(x => x.MenuItemsManagerModule),
        canActivate: [AuthGuard, SuperUserGuard]
    },
    {
        path: 'manage/users',
        loadChildren: () => import('./users/users.module').then(x => x.UsersModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'mystore',
        loadChildren: () => import('./my-store/my-store.module').then(x => x.MyStoreModule),
        canActivate: [AuthGuard, HasSelectedOrganizationGuard]
    },
    {
        path: 'manage/data-versions',
        loadChildren: () => import('./data-versions/data-versions.module').then(x => x.DataVersionsModule),
        canActivate: [AuthGuard, CanManageUsersGuard]
    },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
