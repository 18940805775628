import { SharedModule } from './_shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { HeaderComponent } from './header/header.component';
import {
    MatMomentDateModule,
    MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from '@angular/material-moment-adapter';
function getToken() {
    //has export in front
    return localStorage.getItem('backend-client-token');
}

@NgModule({
    declarations: [AppComponent, HomeComponent, HeaderComponent],
    imports: [
        BrowserModule,
        SharedModule,
        MatMomentDateModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: getToken,
                allowedDomains: ['localhost:10000'],
                disallowedRoutes: ['http://localhost:10000/api/auth']
            }
        })
    ],
    providers: [
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
